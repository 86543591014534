<style scoped lang="less">
  .map-search {
    display: flex;
    flex-direction: column;
  }
  .results {
    height: 100px;
    overflow: auto;
  }
  .item {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .item.active {
    .title, .address {
      color: #2d8cf0;
    }
  }
  .item + .item {
    border-top: 1px solid #EEE;
    margin-top: 10px;
  }
  .item .info {
    flex: 1;
  }
  .title, .address {
    transition: all .3s;
    cursor: pointer;
  }
  .title:hover, .address:hover {
    color: #2d8cf0;
  }
  .title {
    margin-right: 10px;
  }
  .tag + .tag {
    margin-left: 5px;
  }
  .tag {
    padding: 1px 5px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #2d8cf0;
    background-color: #2d8cf0;
    color: #FFF;
  }
</style>

<template>
  <div class="map-search" v-loadingx="loading.load">
    <div style="flex: 1;" ref="map"></div>
    <div>
      <fm-input-new style="width: 100%;margin: 10px 0;" :value="searchKey" @input="search" placeholder="请输入要搜索的地点名称" />
      <div class="scrollbar results" ref="results">
        <div class="item" :data-uid="pickPoint.uid" v-if="pickPoint" :class="{active: choosePoint === pickPoint }">
          <div class="info" @click="show(pickPoint)">
            <div>
              <span class="title">{{pickPoint.title}}</span>
            </div>
            <div class="address">{{pickPoint.address}}</div>
          </div>
          <div>
            <fm-btn @click="show(pickPoint)" class="action" size="small" type="primary">查看</fm-btn>
            <fm-btn @click="removePick" class="action" size="small" type="primary">清除</fm-btn>
            <fm-btn @click="choose(pickPoint)" class="action" size="small" type="primary">选取</fm-btn>
          </div>
        </div>
        <div :data-uid="item.uid" v-for="item in results" :key="item.uid" class="item" :class="{active: choosePoint && choosePoint.uid === item.uid}">
          <div class="info">
            <div>
              <span @click="show(item)" class="title">{{item.title}}</span>
              <span class="tag" v-for="tag in item.tags" :key="tag">{{tag}}</span>
            </div>
            <div @click="show(item)" class="address">
              {{item.address}}
            </div>
          </div>
          <div>
            <fm-btn @click="show(item)" class="action" size="small" type="primary">查看</fm-btn>
            <fm-btn @click="choose(item)" class="action" size="small" type="primary">选取</fm-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadBMapScript, setBoundary, getPointIcon, getCurrentLocation } from '@/components/map/lib'

/**
 * @marked [
 *  {
 *    title: '',
 *    address: '',
 *    point: {
 *      lat: 100,
 *      lng: 100
 *    }
 *  }
 * ]
 */
export default {
  props: {
    marked: { type: Array, default: () => [] },
    searchKey: { type: String, default: null }
  },
  data () {
    return {
      results: [],
      pickPoint: null,
      choosePoint: null,
      loading: {
        load: false
      }
    }
  },
  watch: {
    marked: {
      deep: true,
      handler () {
        this.$map && this.loadMarked()
      }
    }
  },
  methods: {
    show (item) {
      if (!this.$showMarker) {
        this.$showMarker = new window.BMapGL.Marker(item.point, {
          icon: getPointIcon('yellow'),
          title: item.title
        })
        this.$map.addOverlay(this.$showMarker)
      } else {
        this.$showMarker.setPosition(item.point)
        this.$showMarker.setTitle(item.title)
      }
      this.$showMarker.removeEventListener('click')
      this.$showMarker.addEventListener('click', () => {
        this.$map.openInfoWindow(new window.BMapGL.InfoWindow('地址：' + item.address, {
          title: item.title
        }), item.point)
      })
      this.choosePoint = item
      this.$map.flyTo(item.point, 15)
    },
    choose (item) {
      this.$emit('choose', item)
    },
    search (keyword) {
      if (!this.$map || !this.$search) return
      this.$timer && clearTimeout(this.$timer)
      this.$timer = setTimeout(() => {
        if (keyword) {
          if (this.$showMarker) {
            this.$map.removeOverlay(this.$showMarker)
            this.$showMarker = null
          }
          this.$searchMarker && this.$searchMarker.length && this.$searchMarker.forEach(v => {
            this.$map.removeOverlay(v)
          })
          this.$searchMarker = []
          this.results = []
          this.$search.search(keyword)
        }
      }, 1000)
    },
    removePick () {
      this.pickPoint = null
      if (this.$pickMarker) {
        this.$map.removeOverlay(this.$pickMarker)
        this.$pickMarker = null
      }
    },
    mapClick (e) {
      if (e.overlay) {
        e.domEvent.stopPropagation()
        e.domEvent.cancelBubble = true
        return
      }

      new window.BMapGL.Geocoder().getLocation(e.latlng, async ({ address }) => {
        if (!this.$pickMarker) {
          this.$pickMarker = new window.BMapGL.Marker(e.latlng, {
            icon: getPointIcon('green'),
            title: address
          })
          this.$map.addOverlay(this.$pickMarker)
        } else {
          this.$pickMarker.setPosition(e.latlng)
          this.$pickMarker.setTitle(address)
        }
        this.$pickMarker.removeEventListener('click')
        this.$pickMarker.addEventListener('click', () => {
          this.$refs.results.scrollTo(0, 0)
          this.$map.openInfoWindow(new window.BMapGL.InfoWindow('地址：' + address, {
            title: '鼠标选取位置'
          }), e.latlng)
        })
        this.pickPoint = {
          title: '鼠标选取位置',
          address: address,
          point: e.latlng,
          uid: 'pick'
        }
        this.$refs.results.scrollTo(0, 0)
      })
    },
    loadDistrictLayer (name, kind) {
      this.$districtLayer && this.$map.removeDistrictLayer(this.$districtLayer)
      this.$districtLayer = new window.BMapGL.DistrictLayer({
        name: '(' + name + ')',
        kind: kind,
        fillColor: '#FFF',
        fillOpacity: 0,
        strokeColor: '#999',
        strokeWeight: 1,
        strokeOpacity: 0.2,
        strokeStyle: 'dashed'
      })
      this.$map.addDistrictLayer(this.$districtLayer)
    },
    async loadMap () {
      this.loading.load = true
      await loadBMapScript('webgl', '1.0', 't7IKgQ2U43nAPSFj6lGyob7pDDqnXrMf', new Date().getTime())
      const cureentLocation = await getCurrentLocation()
      new window.BMapGL.Geocoder().getLocation(cureentLocation, async ({ address, addressComponents, point }) => {
        console.log(address, addressComponents)
        this.$map = new window.BMapGL.Map(this.$refs.map)
        this.$map.centerAndZoom(point, 10)
        this.$map.enableScrollWheelZoom(true)

        setBoundary({
          map: this.$map,
          address: addressComponents.province + addressComponents.city,
          maskStyle: {
            isBuildingMask: true,
            isPoiMask: true,
            isMapMask: true,
            showRegion: 'inside',
            topFillColor: '#2b85e4',
            topFillOpacity: 0.1,
            sideFillColor: '#2b85e4',
            sideFillOpacity: 0.1
          },
          polylineStyle: {
            strokeColor: '#2b85e4',
            strokeWeight: 1,
            strokeOpacity: 0.2,
            strokeStyle: 'dashed',
            enableClicking: false
          }
        })

        let viewport = await setBoundary({
          map: this.$map,
          address: addressComponents.province + addressComponents.city + addressComponents.district,
          polygonStyle: {
            fillColor: '#2b85e4',
            fillOpacity: 0.1,
            strokeColor: '#2b85e4',
            strokeWeight: 1,
            strokeOpacity: 1,
            strokeStyle: 'solid',
            enableClicking: false
          }
        })

        this.loadSearch(addressComponents.province + addressComponents.city)
        this.loadMarked()

        if (this.searchKey && !this.marked.length) {
          viewport = null
          this.$nextTick(() => {
            this.search(this.searchKey)
          })
        } else {
          this.$nextTick(() => {
            this.$map.setViewport(viewport, {
              margins: [10, 10, 10, 10]
            })
            viewport = null
          })
        }

        this.$map.addEventListener('click', this.mapClick)
        this.loading.load = false

        this.loadDistrictLayer(addressComponents.province + addressComponents.city, 2)
      })
    },
    loadSearch (center) {
      this.$searchMarker = []
      this.$search = new window.BMapGL.LocalSearch(center, {
        onSearchComplete: (results) => {
          if (this.$search.getStatus() === window.BMAP_STATUS_SUCCESS) {
            let s = []
            for (let i = 0; i < results.getCurrentNumPois(); i ++) {
              const item = results.getPoi(i)
              s.push(item)

              const marker = new window.BMapGL.Marker(item.point, {
                title: item.title,
                icon: getPointIcon('blue')
              })
              marker.addEventListener('click', () => {
                this.choosePoint = item
                const el = this.$refs.results.querySelector('[data-uid="' + item.uid + '"]')
                el && el.scrollIntoView()
                this.$map.openInfoWindow(new window.BMapGL.InfoWindow('地址：' + item.address, {
                  title: item.title
                }), item.point)
              })
              this.$map.addOverlay(marker)
              this.$searchMarker.push(marker)
            }
            this.results = s
            this.$map.setViewport(this.results.map(v => v.point))
          } else {
            this.$notice.warning('暂无结果，请更换搜索关键词')
          }
        }
      })
    },
    loadMarked () {
      if (!this.$defaultMarker) {
        this.$defaultMarker = []
      }
      if (this.$defaultMarker.length) {
        this.$defaultMarker.forEach(v => {
          this.$map.removeOverlay(v)
        })
      }
      this.marked.forEach(item => {
        const marker = new window.BMapGL.Marker(new window.BMapGL.Point(item.point.lng, item.point.lat), {
          title: item.title || '已选点',
          icon: getPointIcon('red')
        })
        if (item.address) {
          marker.addEventListener('click', () => {
            this.$map.openInfoWindow(new window.BMapGL.InfoWindow(item.address, {
              title: item.title
            }), item.point)
          })
        }
        this.$map.addOverlay(marker)
        this.$defaultMarker.push(marker)
      })
    }
  },
  mounted () {
    this.$nextTick(this.loadMap)
  },
  destroyed () {
    this.$defaultMarker = null
    this.$showMarker = null
    this.$map = null
    this.$searchMarker = null
  }
}
</script>
